.bridge-wrapper {
  padding-bottom: 286px;
  max-width: 100%;
  width: 872px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  min-height: 900px;

  .page-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .page-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 8px;
  }

  .power-by {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 88.24px;
      height: 24px;
    }
  }

  .form-bridge {
    background: #191b2e;
    border-radius: 16px;
    margin-top: 24px;
    padding: 24px;
    position: relative;

    .chains {
      display: flex;
      align-items: center;
      gap: 16px;

      .swap-ball {
        background: #c1ff99;
        border-radius: 14px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;

        svg {
          color: #080715;
        }

        &:hover {
          color: #080715;
          background: #b7e399;
        }

        &:active {
          color: #080715;
          background: #b7e399;
        }
      }

      .chain-select-dropdown {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
        position: relative;

        .dropdown-name {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: rgba(255, 255, 255, 0.6);
          display: flex;
          justify-content: space-between;
        }
        .input-box {
        }

        .selected-chain {
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          padding: 12px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500 !important;
          font-size: 16px !important;
          line-height: 140% !important;
          cursor: pointer;
          .chain-info {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 12px;

        .chain-select-dropdown {
          width: 100%;
        }

        .swap-ball {
          margin-top: 0;
          transform: rotate(90deg);
        }
      }
    }

    .transfer-amount {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      input {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        width: 100%;
        height: 46px;
        background: #11121b;
        padding: 12px 16px;
        /* White/10 */

        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;

        &::placeholder {
          color: white;
        }
      }

      .balance-info {
        display: flex;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.6);

        span {
          color: white;
        }
        .balance {
          cursor: pointer;
        }
      }
    }

    .recieve-info {
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .actions {
      margin-top: 24px;
      width: 100%;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 0;
      }
    }

    @media screen and (max-width: 700px) {
      padding: 16px;

      .recieve-info {
        .reciever {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          img {
            display: none;
          }
        }
      }
    }
  }

  .down-content {
    margin-top: 64px;

    .icon-view {
      width: 24px;
      height: 24px;
    }

    .transaction-table-mobile {
      display: none;
    }

    .transaction-table {
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      .transaction-header {
        color: rgba(255, 255, 255, 0.7);
        padding: 0 16px;

        .table-column {
          padding-left: 6px;

          &:first-child {
            padding: 0;
          }

          &:last-child {
            padding: 0;
          }
        }
      }

      .transaction-line {
        display: flex;

        .table-column {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 8px;

          &:last-child {
            flex: none;
            width: fit-content;
          }
        }
      }

      .transaction-row {
        background: #191b2e;
        border-radius: 12px;
        padding: 12px 16px;
        margin-top: 8px;
        // cursor: pointer;
      }
    }

    @media screen and (max-width: 700px) {
      .transaction-table {
        display: none;
      }

      .transaction-table-mobile {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 16px;

        .table-mobile-content {
          background: #191b2e;
          border-radius: 16px;
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .content-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;

            .title {
              color: rgba(255, 255, 255, 0.6);
            }

            .value {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 871px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.white-text {
  color: white !important;
}

.address-text {
  color: #c1ff99;
  text-decoration: underline;
}

.reciever {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  img {
    margin-left: 4px;
  }
}
.bridge-wrapper {
  .input-box {
    height: 48px;
    display: flex;
    justify-content: space-between;

    border-radius: 8px;
    border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    background: var(--v-3-natural-1, #191b2e);
    padding: 12px 16px;

    .input-token {
      display: flex;
      align-items: center;
      .input-token-ic {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
  .menu-items {
    top: 8.3rem;

    .menu-item {
      display: flex;
      align-items: center;
      gap: 8px;

      .chain-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: white;
      }
    }

    .menu-item--active {
      background: #808aff14 !important;
      opacity: 1;
      color: #eee;
    }
  }
}

.delivered {
  color: #c1ff99;
  text-transform: capitalize;
}
.inflight {
  color: #ffdf76;
  text-transform: capitalize;
}

.failed {
  color: #f6475d;
  text-transform: capitalize;
}

.processing {
  color: #ffdf76;
  text-transform: capitalize;
}
.no-data {
  margin-top: 20px;
  padding: 12px 16px;
  background: #191b2e;
  border-radius: 12px;
  @media screen and (max-width: 700px) {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}
