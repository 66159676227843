.new-container {
  width: 100%;
  max-width: 872px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  padding-bottom: 286px;

  @media screen and (max-width: 870px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 21px;
  }
}

.frame-427320323,
.frame-427320323 * {
  box-sizing: border-box;
}

.frame-427320323 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-427320322 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.up-to-the-minute-and-accurate-updates-on-cryptocurrency-news {
  color: #ffffff;
  text-align: left;
  font: var(--small-title-bold, 700 24px/140% "Roboto", sans-serif);
  position: relative;
  line-height: 140%;
  align-self: stretch;

  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
}

.frame-427320153 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.aggregated-by-crypto-panic {
  color: var(--white-60, rgba(255, 255, 255, 0.6));
  text-align: left;
  font: var(--body-regular, 400 14px/140% "Roboto", sans-serif);
  position: relative;
}

.image-126 {
  flex-shrink: 0;
  width: 32.23px;
  height: 32px;
  position: relative;
}

.frame-427320321 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-427319432 {
  background: var(--v-3-natural-1, #191b2e);
  border-radius: 16px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;

  @media screen and (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
  }
}

.frame-427319413 {
  background: var(--white-10, rgba(255, 255, 255, 0.1));
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.all {
  color: var(--white-100, #ffffff);
  text-align: left;
  font: var(--body-medium, 500 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427319414 {
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    padding: 12px 14px;
  }
}

.hot {
  color: var(--white-100, #ffffff);
  text-align: left;
  font: var(--body-medium, 500 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427319415 {
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.rising {
  color: #ffffff;
  text-align: left;
  font: var(--body-medium, 500 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427319416 {
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.bullish {
  color: #ffffff;
  text-align: left;
  font: var(--body-medium, 500 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427319417 {
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.important {
  color: #ffffff;
  text-align: left;
  font: var(--body-medium, 500 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427320319 {
  background: var(--v-3-natural-1, #191b2e);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;

  @media screen and (max-width: 700px) {
    padding: 16px;
  }
}

.frame-427320320 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.news-item {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.frame-427320316 {
  padding: 0px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  @media screen and (max-width: 700px) {
    padding: 0px 16px 0px 0px;
  }
}

._25-min {
  color: #ffffff;
  text-align: center;
  font: var(--body-regular, 400 14px/140% "Roboto", sans-serif);
  position: relative;
  width: 60px;

  @media screen and (max-width: 700px) {
    //  text-align: left;
    width: 50px;
  }
}

.rectangle-3332 {
  background: rgba(255, 255, 255, 0.1);
  flex: 1;
  width: 1px;
  position: relative;
}

.frame-427320318 {
  padding: 0px 0px 36px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}

.frame-427320315 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  // flex-shrink: 0;
  position: relative;
}

.cosmos-vs-polkadot-is-there-a-clear-winner {
  color: #ffffff;
  text-align: left;
  font: var(--body-bold, 700 14px/140% "Roboto", sans-serif);
  position: relative;
  align-self: stretch;

  &:hover {
    text-decoration: underline;
  }
}

.link-open {
  width: 100%;
  text-decoration: none;
}

.cosmos-and {
  color: rgba(255, 255, 255, 0.6);
  text-align: left;
  font: var(--body-regular, 400 14px/140% "Roboto", sans-serif);
  position: relative;
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.frame-427320314 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  // flex-shrink: 0;
  position: relative;
}

.frame-427320313 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-427320309 {
  background: var(--nature-2-2, #212333);
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.dot {
  color: var(--main-primary, #c1ff99);
  text-align: left;
  font: var(--body-regular, 400 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427320310 {
  background: var(--nature-2-2, #212333);
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.atom {
  color: var(--main-primary, #c1ff99);
  text-align: left;
  font: var(--body-regular, 400 14px/140% "Roboto", sans-serif);
  position: relative;
}

.frame-427320311 {
  border-radius: 4px;
  border-style: solid;
  border-color: var(--nature-2-2, #212333);
  border-width: 1px;
  padding: 2px 8px 2px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.ambcrypto-com {
  color: var(--warning, #ffdf76);
  text-align: left;
  font: var(--body-regular, 400 14px/140% "Roboto", sans-serif);
  position: relative;
}

.button {
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 40px;
  position: relative;
  &:hover {
    border-color: #34f5ae;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.button2 {
  color: var(--white-100, #ffffff);
  text-align: left;
  font: var(--body-medium, 500 14px/140% "Roboto", sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    color: #34f5ae !important;
  }
}
