.exchange-v2 {
  .position-share-modal .Modal-content {
    min-width: 36rem;
    width: 581px;
    max-width: unset !important;

    .Modal-body {
      padding: 24px !important;
    }
  }

  .position-share-modal .actions {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;

    img {
      width: 24px;
      margin-right: 4px;
      @media screen and (max-width: 700px) {
        width: 16px;
      }
    }
  }

  .checkbox-list {
    display: flex;
    margin-top: 24px;

    > div:not(:last-child) {
      margin-right: 24px;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  img.img-check {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;

    pointer-events: all;
  }

  .position-share-modal button,
  .position-share-modal a {
    background: none;
    color: black;
    text-decoration: none;
    font-size: 1.45rem;
    border: none;
    opacity: 0.9;
  }

  .position-share-modal button {
    background: #c1ff99;
    border-radius: 8px;
    padding: 8px 16px;
    color: black;
  }
  .tweet-link-container {
    a {
      color: black;
    }
  }
  .position-share-modal button:not(:disabled):hover,
  .position-share-modal a:not(:disabled):hover {
    opacity: 1;
  }

  .position-share-modal button:active,
  .position-share-modal a:active {
    opacity: 0.8;
  }

  .position-share-modal .icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .position-share {
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 24px 24px 24px;
    background-size: cover;
    background-position: right;
  }

  .position-share .logo_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
  }

  .position-share .logo {
    height: 32px;
  }

  .position-share .chain_img {
    position: absolute;
    right: 0;
    top: 0;
  }

  .position-share .info {
    list-style: none;
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .position-share .info li {
    padding: 0 12px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      border-right: none;
    }

    &:first-child {
      padding-left: 0;
    }

    padding-bottom: 0;
  }

  .position-share .info > .side.long {
    color: #8fefaa;
  }

  .position-share .info > .side.short {
    color: #f6475d;
  }

  .position-share .pnl {
    color: var(--Green, #8fefaa);
    font-size: 36px;
    font-weight: 700;
    line-height: 120%;
    /* 43.2px */
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    gap: 4px;

    .change-dollar {
      font-size: 16px;
      font-weight: 500;
      line-height: 120%;
      /* 19.2px */
    }
  }

  .position-share .pnl.positive {
    color: #8fefaa;
  }

  .position-share .pnl.negative {
    color: #de3b3b;
  }

  .tweet-link-container {
    height: 40px;

    a {
      height: 100%;
    }
  }

  .position-share .prices {
    > div {
      display: flex;

      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      &:first-child {
        margin-bottom: 4px;
      }

      p {
        margin: 0;
        color: var(--white-60, rgba(255, 255, 255, 0.6));
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        /* 16.8px */
      }
    }

    margin-bottom: 24px;
  }

  .position-share .prices p.price {
    font-weight: 500;
    margin-left: 4px;
    color: #ffdf76;
  }

  .position-share .referral-code {
    display: flex;

    align-items: center;
  }

  .position-share .referral-code div {
    display: flex;
    flex-direction: column;
  }

  .position-share .referral-code .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 8px;
  }

  .position-share .referral-code .referral-code-info {
    margin-left: 1rem;
  }

  .position-share .referral-code img {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
  }

  .position-share .referral-code p {
    margin: 0;
  }

  .position-share .code {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
  }

  .tweet-link-container.disabled {
    cursor: not-allowed;
  }

  .position-share-modal .App-button-option {
    justify-content: center;
  }

  a.App-button-option.tweet-link {
    text-decoration: none;
    width: 100%;
  }

  a.App-button-option.tweet-link.disabled {
    pointer-events: none;
  }

  .image-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0;
  }

  .image-overlay-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #00000055;
    color: #fff;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    float: right;
    padding: 0 0.25rem;
    border-top-left-radius: 5px;
  }

  .image-overlay-wrapper .loading-text {
    font-size: 1.1rem;
    margin: 0 0 0 0.5rem;
  }

  .image-overlay {
    display: flex;
    padding: 0.5rem 0;
    justify-content: center;
    align-items: center;
  }

  .image-overlay .spinning-loader {
    margin-left: 0.5rem;
  }

  .position-share .blue {
    color: #0ecc83 !important;
  }

  .position-share .red {
    color: #de3b3b !important;
  }

  @media (max-width: 700px) {
    .position-share .logo_wrapper {
      margin-bottom: 12px;
    }

    .position-share .referral-code .referral-code-info {
      margin-left: 8px;
    }

    .position-share .pnl .change-dollar {
      font-size: 14px;
    }

    .position-share-modal .Modal-content {
      max-width: calc(100vw - 32px);
      width: 343px;
      min-width: unset;
    }

    .position-share .info {
      margin-bottom: 8px;
    }

    .position-share-modal .Modal-content .Modal-body {
      padding: 8px !important;
    }

    .position-share-modal .Modal-title-bar {
    }

    .position-share {
      padding: 12px;
    }

    .position-share .logo {
      width: 50px;
    }

    .position-share .info {
      font-size: 12px;
    }

    .position-share .pnl {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .position-share .prices {
      margin-bottom: 12px;
    }

    .position-share .prices > div {
      font-size: 12px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    .position-share .prices p {
      font-size: 12px;
    }

    .position-share .prices p.price {
      font-size: 12px;
    }

    .position-share .referral-code {
    }

    .position-share .referral-code .label {
      font-size: 12px;
    }

    .position-share .referral-code p {
      font-size: 12px;
    }

    .position-share-modal .actions {
      margin-top: 16px;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      button {
        margin-right: 0;
      }

      @media screen and (max-width: "600px") {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .position-share-modal .actions .App-button-option {
    }

    .checkbox-list {
      margin-top: 16px;
    }

    .image-overlay-wrapper .loading-text {
      font-size: 1rem;
    }
  }

  .checkbox-item {
    color: var(--white-100, #fff);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
  }
}
