.wrapper-slick {
  position: relative;

  margin-top: 30px;
  width: 1257px;
  padding: 0 32px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    padding: 0 22px;
  }

  .swiper {
    overflow: visible;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-slide {
      &.swiper-slide-prev,
      &.swiper-slide-next {
        visibility: hidden;
      }

      &.swiper-slide-active {
        visibility: visible;
      }

      .child {
        position: relative;

        height: 230px;
        border-radius: 24px;

        padding: 34px 0 40px 33px;

        @media (max-width: 1024px) {
          padding: 50px 0 52px 24px;
        }

        @media (max-width: 600px) {
          padding: 42px 0 47px 16px;
        }

        .title {
          font-weight: 400;
          font-size: 24px;
          line-height: 140%;

          font-family: "Carter One";
          color: #ffffff;

          @media (max-width: 1024px) {
            font-size: 16px;
          }

          @media (max-width: 600px) {
            font-size: 12px;
          }
        }

        .content {
          margin: 10px 0 16px 0;

          font-family: "Roboto";
          font-weight: 700;
          font-size: 36px;
          line-height: 140%;

          span {
            color: #f9e34e;
          }

          @media (max-width: 1024px) {
            font-size: 20px;
          }

          @media (max-width: 600px) {
            font-size: 14px;
          }
        }

        a {
          text-decoration: none;

          .action {
            font-family: "Roboto";

            display: flex;
            align-items: center;
            padding: 8px 16px;
            gap: 4px;

            width: fit-content;
            background: #c1ff99;
            border-radius: 8px;

            font-weight: 500;
            font-size: 14px;
            line-height: 140%;

            color: #080715;
          }
        }

        .frame {
          position: absolute;

          top: -30px;
          right: 24px;

          @media (max-width: 1024px) {
            width: 206px;

            top: 24px;
            right: 18px;
          }

          @media (max-width: 600px) {
            width: 150px;

            top: 80px;
            right: 6px;
          }
        }
      }
    }
  }

  .referrals {
    background: linear-gradient(287.56deg, #041fa9 0%, #041fa9 0.01%, #850303 100%);
  }

  .sticker {
    background: linear-gradient(90deg, #ff3a46 0%, #4f0074 100%);
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;

    background: rgba(217, 217, 217, 0.24);

    &.swiper-pagination-bullet-active {
      background: #d9d9d9;
    }
  }
}
