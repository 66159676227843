.swap-arregator-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));

  .label-box {
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }

  .content-box {
    display: flex;
    align-items: center;
    gap: 16px;

    .content-icon-styled {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      transition: transform ease-out 0.1s;
      z-index: 2;

      .reload-placeholder {
        position: absolute;
        left: 0;
        z-index: 1;
        top: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: 0.5s;
        transform: scale(0.9);
        z-index: -1;
      }

      &:hover::before {
        transform: scale(1.1);
        box-shadow: 0 0 15px #c1ff99;
      }

      &:hover {
        color: #c1ff99;
        box-shadow: 0 0 5px #c1ff99;
        text-shadow: 0 0 5px #c1ff99;
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }

  @keyframes sonarEffect {
    0% {
      opacity: 0.3;
    }

    40% {
      opacity: 0.5;
      box-shadow: 0 0 0 0.5px rgba(255, 255, 255, 0.1), 0 0 2px 2px #3851bc, 0 0 0 4px rgba(255, 255, 255, 0.5);
    }

    100% {
      box-shadow: 0 0 0 0.5px rgba(255, 255, 255, 0.1), 0 0 2px 2px #3851bc, 0 0 0 4px rgba(255, 255, 255, 0.5);
      transform: scale(1.1);
      opacity: 1;
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.powered-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 24px;

  .powered-label {
    color: #999691;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }

  .microswap-icon {
    cursor: pointer;
  }
}

.rate-exchange-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;

  .price-token-styled {
    justify-content: end;
  }

  .desc-rate-value {
    color: #c1ff99 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}

.list-section-wrapper-none {
  @media screen and (max-width: 767px) {
    padding-bottom: 24px !important;
  }
}

.list-section-wrapper {
  padding: 24px 32px 32px 32px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  // min-height: 150px;

  .content-section-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .path-swaps-wrapper {
    width: 100%;
    padding: 20px;
    padding-bottom: 0px;

    @media screen and (max-width: 767px) {
      padding: 15px;
      padding-bottom: 0px;
    }
  }

  .item-section-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;

    .from-token-head-group,
    .to-token-head-group {
      padding: 4px 8px;
      border-radius: 100px;
      // position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      gap: 4px;
      z-index: 10;

      img {
        width: 24px;
        height: 24px;
        border-radius: 100px;

        @media screen and (max-width: 767px) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .item-section-wrapper {
    position: relative;
    width: 100%;
    // height: 80px;
    // border: 1px dashed #999691;
    // border-top: none !important;

    @media screen and (max-width: 1350px) {
    }

    .from-token-head-group,
    .to-token-head-group {
      padding: 2px 4px;
      border-radius: 8px;
      // position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      gap: 4px;
      z-index: 1;
      background: #191b2e;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .from-token-head-group {
      left: -25px;
    }

    .to-token-head-group {
      right: -25px;
    }

    .token-swap-path-wrapper {
      width: 100%;
      height: fit-content;
      display: flex;
      // align-items: center;
      justify-content: space-evenly;
      overflow: auto;
    }

    .dexs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: start;
    }

    .road-exchange-group {
      display: flex;
      width: 100%;
      border-left: 1px dashed #999691;
      border-right: 1px dashed #999691;
      position: relative;
      // left: 0;
      // bottom: -30px;
      // align-items: center;
      justify-content: space-evenly;
      z-index: 1;
      padding: 16px 0px 0px 0px;

      &.last-item {
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 48px;
          top: 0;
          left: 0;
          // border-bottom: 1px dashed #999691;
          border-left: 1px dashed #999691;
          border-right: 1px dashed #999691;
          z-index: -1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 48px;
        top: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1px dashed #999691;
        // border-left: 1px dashed #999691;
        // border-right: 1px dashed #999691;
      }

      @media screen and (max-width: 1365px) {
        // bottom: -21px;
      }

      @media screen and (max-width: 767px) {
        // bottom: -18px;
      }
    }

    @media screen and (max-width: 767px) {
      .token-swap-path-wrapper {
        justify-content: initial;
        gap: 16px;
        overflow: auto;
        padding: 0px 10px;
      }

      // height: 64px;
    }

    ::-webkit-scrollbar {
      display: none !important;
    }
  }

  .value-token {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    text-decoration: none;
  }

  .value-token-child {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    // text-transform: uppercase;
    text-decoration: none;
  }

  .rate-exchange-token {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    height: fit-content;
    padding: 4px 8px;
    background: #060810;
    margin: 20px 15px 0px;
    z-index: 1;

    .rate-value {
      color: #999691;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .to-token-group-container {
    min-width: 125px;
    padding: 8px;
    border-radius: 16px;
    background: #141312;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: fit-content;
    z-index: 1;

    @media screen and (max-width: 1350px) {
      min-width: unset;
      margin-top: 13px;
    }

    .dex-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--Text-Text_Secondary, #999691);
      text-transform: capitalize;
      margin-left: 2px;

      img {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-right: 4px;
      }

      @media screen and (max-width: 1350px) {
        display: none;
      }
    }
  }

  .from-token-group,
  .to-token-group {
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
  }

  .exchange-icon {
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background: #060810;
    // align-items: center;
    margin-top: 20px;
    z-index: 1;

    img {
      width: 16px;
      height: 16px;
    }
  }

  @media screen and (max-width: 1100px) {
  }

  @media screen and (max-width: 767px) {
    padding: 16px 8px;

    .from-token-head-group,
    .to-token-head-group {
      font-size: 12px;
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .value-token {
      font-size: 12px;
    }

    .value-token-child {
      font-size: 12px;
    }

    .rate-value {
      color: #999691;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }

    .from-token-group,
    .to-token-group {
      img {
        width: 12px;
        height: 12px;
      }
    }

    .exchange-icon {
      img {
        width: 8px;
        height: 8px;
      }
    }

    .road-exchange-group {
      // bottom: -11px;
    }
  }
}

.spot-aggregator-wrapper {
  .Modal {
    padding: 0px 16px;

    .Modal-content {
      max-width: 400px !important;
      width: 100% !important;

      .Modal-title-bar {
        margin: 12px 24px;
      }
    }
  }
}

.align-right {
  align-items: center !important;
}

.spot-arregator-container {
  margin-top: 0 !important;
  padding-top: 48px !important;

  .Exchange-content {
    height: 100% !important;
    flex: 1;
  }
}

.warning-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--Base-Unit-S-12, 12px);
  border: 1px solid var(--Functional-Orange, #ffb200);
  background: rgba(255, 178, 0, 0.1);
  padding: 12px 16px;
  gap: 16px;
  // margin-bottom: 4px;

  .warning-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      margin-right: 8px;
    }
  }

  .text-desc {
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }
}

.stroke-line {
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: 16px;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.theme--light {
  .stroke-line {
    background: rgba(13, 26, 22, 0.07) !important;
  }

  .pin-tokens {
    .token-info {
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
    }
  }
}

.pin-tokens {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  .token-info {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px;
    border-radius: var(--Base-Unit-XS-8, 8px);
    // border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
    cursor: pointer;
    background-color: #332e2d;

    &:hover {
      background: #ffffff1a;
    }

    img {
      width: 20px;
      height: 20px;
    }

    .symbol {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}

.Exchange-swap-box-inner {
  padding: 16px 24px !important;

  .Exchange-swap-max {
    &:hover {
      background: #b7e399 !important;
    }
  }
}

.Tooltip-popup {
  opacity: 1 !important;
}

.TokenSelector {
  .Modal {
    padding: 0 !important;
  }
}

.Exchange-swap-button,
.Confirmation-box-button {
  &:hover {
    background-color: #b7e399;
  }
}

.theme--light {
  .spot-aggregator-wrapper {
    .Exchange {
      background: none !important;
    }

    .Exchange-lists {
      // border: 1px solid rgba(13, 26, 22, 0.07) !important;
      .value-token {
        color: #0d1a16 !important;
      }

      .road-exchange-group {
        border-color: rgba(13, 26, 22, 0.7) !important;

        &::before {
          border-color: rgba(13, 26, 22, 0.7) !important;
        }
      }

      .rate-exchange-token {
        background-color: #f2f5f7;

        .rate-value {
          color: rgba(13, 26, 22, 0.7) !important;
        }
      }

      .to-token-group-container {
        background-color: #fff;

        a {
          color: #0d1a16 !important;
        }

        .dex-container {
          color: rgba(13, 26, 22, 0.7) !important;
        }
      }

      .exchange-icon {
        background: #f2f5f7 !important;
      }
    }

    .Exchange-left {
      background: none !important;

      &:has(.TradeHistory.with-scroll) {
        overflow-y: hidden;
      }

      .section-with-scroll {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }

    .list-section-wrapper {
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
    }

    .chart-container-border {
      background: #fff !important;
    }

    .ExchangeChart,
    .box-trade-swap,
    .Exchange-list {
      background-color: #fff !important;
      // box-shadow: 0 2px 4px #0003;
      border: 1px solid rgba(13, 26, 22, 0.07);
    }

    .Exchange-list {
      border-radius: 16px;
    }

    .chart-container-border {
      background: #fff !important;
    }

    .Exchange-right {
      background: none !important;
    }

    .Tab-option {
      color: rgba(13, 26, 22, 0.7);

      &.active {
        background: rgba(13, 26, 22, 0.07);
        color: #0d1a16;
      }
    }

    .powered-wrapper {
      .powered-label {
        color: #0d1a16b2 !important;
      }
    }

    .from-token-head-group,
    .to-token-head-group {
      background: #f2f5f7 !important;
    }

    .Confirmation-box-wrapper {
      background: #fff !important;
    }

    .Modal-content {
      background: #f2f5f7 !important;
    }

    .btn-clear-all {
      div {
        color: #02b27f !important;
      }
    }

    .transaction-status {
      path {
        fill: #02b27f !important;
      }
    }

    .percent-slippage {
      color: #02b27f !important;
    }

    .Exchange-swap-max {
      &:hover {
        background: #02845e !important;
      }
    }
  }
}

.no-result-searched {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
