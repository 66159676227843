/* .menu-items:focus-visible {
  border: 1px solid #262638;
} */
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.user-address {
  /* margin-left: 1rem; */
  margin-right: 1rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.App-header-user-address:hover {
  background: #2b2828;
  /* filter: brightness(1.5); */
}

.menu-items {
  position: absolute;
  right: 0;
  top: 4.3rem;
  min-width: 216px;
  width: 100%;

  transform-origin: top right;

  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  opacity: 1 !important;
  border: none;

  overflow: hidden;
  border-radius: 16px;
  background: var(--Background-Tooltip_Background, #33302e) !important;

  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 12px 16px;
  border-radius: var(--base-unit-s-12, 12px);
  background: var(--Background-Tooltip_Background, #33302e) !important;

  opacity: 1;
}
.menu-item a:hover {
  color: #fff;
}
.App-header-user-address .menu-item-disconnect {
  color: #f6475d;
}
.menu-item:hover {
  background: var(--Nature-2, #242323) !important;
  opacity: 1;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}
