.exchange-v2 {
  background: #000;
  font-weight: 500;

  .Exchange-updated-ui {
    background: url("../../img/trade/color_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  @media screen and (max-height: 700px) {
    .Exchange-swap-box-inner {
      .Tab-option {
        font-size: 12px !important;
      }
    }
  }

  .PositionsList {
    @media screen and (max-height: 900px) {
      .Modal-content {
        .content-container {
          max-height: 60vh !important;
        }
      }
    }

    @media screen and (max-height: 600px) {
      .Modal-content {
        .content-container {
          max-height: 50vh !important;
        }
      }
    }

    @media screen and (max-height: 450px) {
      .Modal-content {
        .content-container {
          max-height: 40vh !important;
        }
      }
    }
  }

  .chart-token-selector--current {
    font-size: 2.1rem;
    font-weight: 700;
    /* margin-right: 1rem; */
    color: white;
  }

  .chart-token-menu-items {
    .menu-items {
      width: 424px !important;
    }
  }

  @media screen and (max-width: 767px) {
    .chart-token-menu-items.menu-items {
      top: 4.5rem;
    }
  }

  .chart-token-menu-items .menu-item {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #fff;
  }

  .menu-item-active {
    background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
  }

  .Exchange {
    max-width: 155rem;
    margin: auto;
    justify-content: space-between;
  }

  .Exchange-trigger-order-info,
  .Exchange-trigger-order-info a {
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid #23263b;
  }

  .Exchange-list-tab-container {
    display: grid;
    grid-template-columns: auto auto;
    /* padding-left: 0.8rem; */
    /* padding-right: 0.8rem; */
    /* margin-top: 1.5rem; */
    margin-bottom: 16px;
  }

  .Exchange-should-show-position-lines {
    font-size: 1.4rem;
    gap: 16px;
  }

  .Exchange-should-show-position-lines>.group-actions {
    display: flex;
  }

  .Exchange-should-show-position-lines>.group-switch {
    display: flex;
    align-items: center;

    border: 1.5px solid rgb(117, 117, 143);
    background: transparent;
    width: 100%;
    max-width: 114px;
    height: 34px;
    border-radius: 5px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;

    font-size: 14px;
    line-height: 18px;
  }

  .Exchange-should-show-position-lines>.group-switch>span {
    margin: 0 4px;
    color: rgb(117, 117, 143);
  }

  .add-to-position-modal {
    .square-container {
      .align-right {
        font-weight: 700 !important;
      }

      .position-change-line {
        .Exchange-list-info-label {
          @media screen and (max-width: 700px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .group-switch-mobile>span {
    margin: 0 4px;
    color: rgb(117, 117, 143);
  }

  .group-switch-mobile {
    display: none;
    align-items: center;

    margin-bottom: 16px;
    border: 1.5px solid rgb(117, 117, 143);
    background: transparent;
    width: 100%;
    max-width: 114px;
    height: 34px;
    border-radius: 5px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;

    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 767px) {
    .Exchange-should-show-position-lines>.group-switch {
      display: none;
    }

    .group-switch-mobile {
      /* display: flex; */
    }
  }

  .Exchange-should-show-position-lines .active {
    opacity: 1;
  }

  .Exchange-should-show-position-lines svg {
    border-radius: 4px;
  }

  .Exchange-empty-positions-list-note {
    padding-bottom: 0.465rem;
  }

  .position-loading-icon {
    display: inline-block;
    font-size: 1.25rem;
    margin-left: 0.8rem;
    color: #3749e6;
    vertical-align: middle;
    margin-top: -0.31rem;
  }

  .Exchange-settings-row {
    .Checkbox-label {
      font-size: 14px !important;
    }
  }

  .Exchange-settings .App-cta {
    margin-top: 1.5rem;
  }

  .Exchange-content {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1.5rem;
    padding: 3.1rem;
    padding-top: 0rem;
    margin-top: -1.5rem;
  }

  .Exchange-position-list-orders {
    white-space: nowrap;
  }

  .Exchange-lists.large {
    display: block;
    background: #191b2e;
    border-radius: 16px;
    padding: 16px;
  }

  .Exchange-lists.small {
    display: none;
  }

  .Exchange-right {
    display: grid;
    grid-template-rows: auto 1fr;
    border-left: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    background: var(--Natural-1, #1a1919);
  }

  @media screen and (min-width: 1351px) {
    .Exchange-right {
      max-height: calc(100vh - 62px);
      overflow-y: auto;
    }
  }

  // .Exchange-right::-webkit-scrollbar {
  //   width: 2px;
  // }

  .Exchange-leverage-slider-settings {
    font-size: var(--font-sm);
    margin-bottom: 0.8rem;
  }

  .Exchange-leverage-slider-settings .Checkbox {
    display: grid;
    grid-template-columns: 1fr auto;
    direction: rtl;
  }

  .Exchange-wallet-tokens {
    position: relative;
    min-height: 15.5rem;
    display: none;
  }

  .Exchange-wallet-tokens-content {
    position: absolute;
    top: 1.5rem;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .Exchange-bottom-header-item {
    display: inline-block;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 0.155rem;
    opacity: 0.7;
    cursor: pointer;
  }

  .Exchange-bottom-header-item.active {
    opacity: 1;
    pointer-events: none;
  }

  .Exchange-swap-connect-wallet {
    text-align: center;
    cursor: pointer;
    border: 1px solid rgb(50, 50, 50);
    border-radius: 3px;
  }

  .Exchange-swap-connect-wallet:hover {
    background: #272e33;
    border-radius: 0.6rem;
  }

  .Exchange-swap-settings.Exchange-swap-txns-status {
    text-align: right;
    margin-right: 1.5rem;
  }

  .Exchange-swap-account .Exchange-swap-address:hover,
  .Exchange-swap-account .Exchange-swap-txns-status:hover {
    opacity: 1;
  }

  .Exchange-leverage-slider {
    margin-top: 1.5rem;
    margin-bottom: 20px;
    padding: 0 0.8rem;
  }

  .Exchange-swap-leverage-options {
    text-align: right;
  }

  .Exchange-swap-leverage-option {
    display: inline-block;
    margin-left: 0.8rem;
    padding: 0 0.8rem;
    border-radius: 3px;
    background: #5b2ab0;
    color: white;
    opacity: 0.7;
    cursor: pointer;
  }

  .Exchange-swap-leverage-option.active {
    background: #842ec9;
    opacity: 1;
    pointer-events: none;
  }

  .Exchange-swap-leverage-option:hover {
    opacity: 1;
  }

  .Exchange-swap-leverage-unlock-icon {
    font-size: 0.8rem;
    vertical-align: middle;
    margin-top: -0.31rem;
  }

  .Exchange-swap-placeholder {
    height: 11.175rem;
    position: relative;
  }

  .PositionEditor-info-box {
    margin-bottom: 1.6rem;
  }

  .PositionEditor-keep-leverage-settings .Checkbox {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    direction: rtl;
    font-size: var(--font-sm);
    margin-bottom: 0.465rem;
  }

  .PositionEditor-accept-profit-warning .Checkbox {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    direction: rtl;
    font-size: var(--font-sm);
    margin-bottom: 0.465rem;
  }

  .PositionEditor-allow-higher-slippage .Checkbox {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    direction: rtl;
    font-size: var(--font-sm);
    margin-bottom: 0.465rem;
  }

  .ExchangeChart-range-option {
    display: inline-block;
    margin-right: 1.5rem;
    font-size: var(--font-sm);
    cursor: pointer;
    opacity: 0.6;
  }

  .ExchangeChart-range-option:hover {
    opacity: 0.8;
  }

  .ExchangeChart-range-option.active {
    opacity: 1;
  }

  .ExchangeChart {
    position: relative;
    height: 49.6rem;
    background: #1A1919;
    border-radius: 16px;
    /* margin-right: 16px; */
    iframe {
      border-radius: 16px;
    }
  }

  .ExchangeChart-main-price {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
  }

  .ExchangeChart-title {
    font-size: 2.15rem;
    font-weight: bold;
  }

  .ExchangeChart-info-label {
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 140%;
    opacity: 1;
    color: #807E7C;
  }

  .ExchangeChart-top-inner {
    display: flex;
    gap: 24px;
    align-items: center;
    height: 100%;
    /* padding-left: 0.3rem; */
  }

  .ExchangeChart-dollar-sign {
    display: inline-block;
    margin-right: 0.465rem;
    opacity: 0.7;
  }

  .ExchangeChart-main-price-text {
    display: inline-block;
  }

  .ExchangeChart-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: auto;
    z-index: 2;
  }

  .ExchangeChart.tv .ExchangeChart-top {
    position: relative;
    padding: 0 16px;
    z-index: 2;
    background: #191b2e;
    border-radius: 16px;
    align-items: center;
  }

  .ExchangeChart.tv .ExchangeChart-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 91px;
    z-index: 1;
  }

  .ExchangeChart-bottom-controls {
    display: inline-block;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 4.65rem;
    padding: 0.8rem 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-header {
    position: absolute;
    display: flex;
    top: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    z-index: 5;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    font-size: var(--font-sm);
    padding: 0.8rem 0;
    margin-left: 3.1rem;
    white-space: nowrap;
    z-index: 2;
  }

  .ExchangeChart-bottom-stats-label {
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
  }

  .ExchangeChart-bottom-stats-value {
    display: inline-block;
    margin-left: 0.31rem;
    margin-right: 1.4rem;
  }

  .length-5 .ExchangeChart-bottom-stats-value {
    width: 6.2rem;
  }

  .length-4 .ExchangeChart-bottom-stats-value {
    width: 5.25rem;
  }

  .length-3 .ExchangeChart-bottom-stats-value {
    width: 4.35rem;
  }

  .length-2 .ExchangeChart-bottom-stats-value {
    width: 3.41rem;
  }

  .length-1 .ExchangeChart-bottom-stats-value {
    width: 3.48rem;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    inset: 0;
    /* bottom: 0.8rem;
    left: 0;
    right: 0;
    top: 0.8rem; */
  }

  .Exchange-price-tooltip {
    font-size: var(--font-sm);
    background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
    padding: 0.9rem;
    border: 1px solid rgba(30, 9, 94, 0.5);
    line-height: 2rem;
  }

  .Exchange-price-time {
    font-size: 1.25rem;
    font-weight: normal;
  }

  .Error-modal .Modal-content {
    width: 31rem;
  }

  .PositionEditor .Modal-content {
    width: 38rem;
    position: absolute;
    max-height: 90vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
    border-radius: 16px;
    /* background: #212333; */
  }

  .PositionEditor .Modal-content .Modal-body {
    overscroll-behavior: smooth;
    max-height: calc(80vh - 8.525rem);
    overflow-y: auto;
    padding-right: 0.5rem;
    margin: 1.5rem;
    margin-right: 1rem;
  }

  .PositionEditor .Tab {
    margin-bottom: 1.05rem;
  }

  .PositionEditor-token-symbol {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: right;
    color: #fff;
  }

  .ExchangeChart-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .Exchange-list-modal .Modal-content {
    width: 36rem;
  }

  .Exchange-list {
    width: 100%;
  }

  .Position-list-order {
    white-space: nowrap;
    margin-top: 8px;
  }

  .order-error span {
    text-decoration-color: var(--error-red);
    color: var(--error-red);
  }

  .order-error .Tooltip-popup {
    white-space: pre-line;
  }

  .Exchange-list .App-card {
    margin-bottom: 1.6rem;
    background: #242323;
  }

  .Exchange-list-item-error {
    color: #f6475d;
    margin-top: 0.465rem;
    font-size: var(--font-sm);
  }

  .Exchange-list.small {
    display: none;
  }

  button.Exchange-list-action {
    font-size: var(--font-base);
    padding: 0;
    border-radius: 3px;
    background: none;
    border: none;
    color: #7EE55C;
    /* margin: 0 0.3rem; */
    margin-right: 16px;
  }

  div.chains-btn {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    border-radius: 4px;
    border: 1px solid var(--Yellow, #7EE55C);
    background: rgba(59, 222, 183, 0.1);
  }

  div.chains-btn:hover {
    border-color: #5a9d43;
  }

  div.chains-btn * {
    color: var(--Yellow, #c1ff99);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    /* 14.4px */
  }

  button.Exchange-list-action:hover {
    color: #67f3c9;
  }

  table.Exchange-list {
    border-collapse: collapse;
    /* background: #212333; */
    border-radius: 4px;
  }

  table.Exchange-list th,
  table.Exchange-list td {
    text-align: left;
    padding: 1.05rem;
    /* padding-left: 1.5rem; */
    padding-left: 11px;
  }

  table.Exchange-list.Orders th,
  table.Exchange-list.Orders td {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  /* table.Exchange-list tr {
    border-bottom: 1px solid #ffffff18;
  } */

  table.Exchange-list {
    td:has(img[alt="tk"]) {
      img[alt="tk"] {
        width: 32px;
        height: 32px;
      }

      &>div {
        display: flex;
        align-items: center;
        gap: 8px;

        .tk_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    tr:last-child {
      border-bottom: none;
    }
  }

  table.Exchange-list tr:hover {
    /* background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(38, 43, 71, 1) 100%);
    box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01); */
  }

  table.Exchange-list tr.Exchange-list-header {
    background: none;
    font-size: var(--font-md);
  }

  table.Exchange-list tr.Exchange-list-header th {
    /* padding-top: 1.4rem;
    padding-bottom: 1.4rem; */
    padding: 0 11px;
    font-size: #b9b9b9;
    font-size: 13px;
  }

  table.Exchange-list th {
    opacity: 1;
    font-weight: 500;
  }

  // .Exchange-leverage-box {
  //   margin-bottom: 1.05rem;
  // }

  .Exchange-swap-box-info {
    margin-bottom: 1.05rem;
  }

  .Exchange-info-row {
    display: grid;
    grid-template-columns: auto auto;
    font-size: var(--font-sm);
    margin-bottom: 8px;
  }

  .Exchange-info-row:last-child {
    margin-bottom: 0;
  }

  .Exchange-info-row.top-line {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }

  .Exchange-info-row.handler {
    grid-template-columns: 1fr auto auto;
    cursor: pointer;
  }

  .Exchange-info-row.isExpand img {
    rotate: 0deg !important;
  }

  .Exchange-info-row.handler img {
    width: 16px;
    height: 16px;
    rotate: 180deg;
  }

  .bold {
    font-weight: 700;
  }

  .Exchange-info-row.handler div.align-right {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #c1ff991a;
    margin-left: auto;

    color: #c1ff99;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
    /* 15.6px */
  }

  .Exchange-info-row.handler div.red-value {
    color: red;
  }

  .Exchange-info-row.handler div.align-right.red-value {
    background: #f6475d1a;
    color: #f6475d;
  }

  .Exchange-info-label {
    // opacity: 0.6;
    margin-right: 0.8rem;
  }

  .Exchange-info-value-warning {
    color: #f6475d;
  }

  .Confirmation-box-info,
  .Confirmation-box-info a,
  .Confirmation-box-warning,
  .Confirmation-box-warning a {
    font-size: var(--font-sm);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .Confirmation-box-info,
  .Confirmation-box-info a {
    opacity: 0.7;
  }

  .Confirmation-box-warning,
  .Confirmation-box-warning a {
    color: #f6475d;
  }

  .Exchange-swap-usd {
    display: inline-block;
    font-size: var(--font-sm);
  }

  .Exchange-swap-box {
    width: 41.85rem;
    display: grid;
    position: relative;
  }

  .Exchange-swap-market-box-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }

  .Exchange-swap-box-inner {
    padding: 16px 16px 24px 16px !important;
  }

  .Available {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Exchange-swap-market-box {
    /* padding: 1.5rem; */
    margin-top: 1.25rem;
    /* padding-bottom: 1.5rem; */
  }

  .Exchange-swap-market-box .App-card-divider {
    margin: 0 -1.5rem 12px;
    background: none;
  }

  .Exchange-swap-box .Radio {
    margin-bottom: 1.05rem;
  }

  .Exchange-swap-box .Overlay-content-outer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 15.5rem;
  }

  .Exchange-swap-box .Overlay-content {
    padding: 1.05rem;
  }

  .Exchange-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Selector .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    overflow: hidden;
    border: none;
  }

  .Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
    scroll-margin-right: 38.75rem;
    overscroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 0.5rem;
    max-height: calc(100vh - 7.75rem);
    overflow-y: auto;
  }

  /* .Exchange-swap-box .Tab {
    margin-bottom: 1.05rem;
  } */

  .Exchange-swap-section {
    padding: 12px;
    margin-bottom: 24px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    transition: all 150ms ease-in-out;
    /* box-shadow: inset 0 0 3rem 0.5rem hsl(0deg 0% 100% / 1%); */
  }

  .Exchange-swap-section:has(input:focus) {
    background: rgba(0, 0, 0, 1);
    outline: 1px solid #c1ff99;
  }

  .Exchange-swap-section--v2 {
    padding: 12px;
    margin-bottom: 16px;
    margin-top: 16px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }

  .Exchange-swap-option-tabs.Tab.block .Tab-option {
    padding: 12px 1.05rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Exchange-swap-order-type-tabs {
    margin-top: 0;
    margin-bottom: 16px;
  }

  /* .Tab.block .Tab-option.active .Tab-option-icon,
  .Tab.block .Tab-option:hover .Tab-option-icon {
    filter: invert(1);
  } */
  .Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
    margin-top: -0.155rem;
    transform: scale(0.75);
    vertical-align: middle;
    margin-left: 4px;
  }

  .Exchange-swap-section-top {
    display: grid;
    grid-template-columns: auto auto;
    font-size: var(--font-sm);
    padding-bottom: 1.25rem;
    font-weight: 400;
  }

  .Exchange-swap-section-bottom {
    display: grid;
    grid-template-columns: 1fr auto;
    padding-bottom: 0.31rem;
    align-items: center;
  }

  .Exchange-swap-message {
    font-size: var(--font-sm);
    opacity: 0.7;
    margin: 0.5rem 0;
  }

  .Exchange-swap-input-container {
    position: relative;
    overflow: hidden;
    height: 100%;
    margin-top: 2px;
  }

  .Exchange-swap-max {
    position: absolute;
    right: 1.25rem;
    top: 0;
    padding: 2px 12px;
    color: #080715;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer;
    font-size: var(--font-sm);
    background: #c1ff99;
  }

  .Exchange-swap-max:hover {
    color: #080715;
    background: #c1ff99;
  }

  .Exchange-swap-section-bottom .TokenSelector-box {
    font-size: 2.1rem;
  }

  .Exchange-leverage-box .TokenSelector-caret {
    font-size: var(--font-base);
    margin-left: 0.31rem;
  }

  input.Exchange-swap-input {
    padding: 0;
    max-width: 100%;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding-right: 2rem;
    font-family: "Roboto";
  }

  input.Exchange-swap-input:hover {
    opacity: 0.6;
  }

  input.Exchange-swap-input:focus {
    opacity: 1;
  }

  .Exchange-swap-input::placeholder {
    color: white;
  }

  input.Exchange-swap-input.small {
    max-width: 14.725rem;
  }

  .Exchange-swap-button-container {
    /* padding-top: 0.31rem; */
  }

  .Exchange-swap-button {
    display: block;
    width: 100%;

    &:hover {
      background-color: #b7e399;
    }
  }

  .Exchange-swap-ball-container {
    position: relative;
    z-index: 1;
  }

  .Exchange-swap-ball-icon {
    text-align: center;
    display: block;
    transform: rotate(90deg);
    font-size: 2rem;
    opacity: 0.7;
  }

  .Exchange-swap-ball {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 50%;
    margin-left: -1.7825rem;
    top: -43px;
    /* border-radius: 3.1rem; */
    cursor: pointer;
    user-select: none;
    background: #c1ff99;
    color: #080715;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
  }

  .Exchange-swap-ball-red {
    background: #f6475d;
    color: white;
  }

  .Exchange-swap-ball-red:hover {
    background: #dc3a50 !important;
  }

  .Exchange-swap-ball:hover {
    background: #b7e399;
  }

  .Exchange-swap-ball:hover .Exchange-swap-ball-icon {
    opacity: 1;
  }

  table.Exchange-list-small {
    width: 100%;
    background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
  }

  table.Exchange-list-small th,
  table.Exchange-list-small td {
    font-weight: normal;
    text-align: left;
    padding: 1.05rem;
  }

  .Exchange-list-card-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0.8rem;
  }

  .Exchange-list-title {
    width: 3.1rem;
    display: inline-block;
    white-space: nowrap;
  }

  .Exchange-list-info-label {
    /* font-size: var(--font-sm); */
    font-size: 12px;
    margin-top: 0.31rem;
  }

  .Exchange-list-info-label span:not(.muted) {
    font-weight: 400;
    /* color: #34f5ae; */
  }

  .Exchange-list-muted {
    font-size: var(--font-sm);
  }

  .Exchange-list-card .Exchange-list-side {
    text-align: right;
  }

  .Exchange-list-card .Exchange-list-size-info {
    margin-bottom: 0.8rem;
  }

  .Confirmation-box {
    font-size: 2rem;
  }

  .Confirmation-box-main {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0 2.325rem;
    font-size: 14px;
  }

  .Confirmation-box-main-icon {
    margin: 0.5rem 0;
  }

  .Confirmation-box-main-icon:after {
    content: "↓";
  }

  .Confirmation-box-main-icon.dot:after {
    content: "•";
  }

  .Confirmation-box .Modal-content {
    width: 36rem;
  }

  .Confirmation-box-row {
    margin-top: 0rem;

    .Confirmation-box-button {
      margin-top: 0;
    }

    padding-top: 8px;
  }

  .Confirmation-box-button {
    width: 100%;
    height: 40px;
  }

  .Exchange-footer {
    text-align: center;
    padding-top: 3.1rem;
    height: 7.75rem;
  }

  .cancel-order-btn {
    margin-right: 1.5rem;
    background: none;
    border: none;
    color: white;
    padding: 0;
  }

  .cancel-order-btn:hover {
    opacity: 0.8;
  }

  .cancel-order-btn:disabled {
    opacity: 0.6;
  }

  .chart-positions:hover {
    opacity: 0.8;
  }

  .chart-positions.span:hover {
    opacity: 1;
  }

  .al-swap .Tooltip-popup {
    min-width: 25rem;
  }

  .position-list-collateral {
    display: flex;
    align-items: flex-end;
  }

  .position-list-collateral .edit-icon {
    cursor: pointer;
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    opacity: 0.7;
  }

  .position-list-collateral .edit-icon:hover {
    opacity: 1;
  }

  @media (max-width: 1500px) {
    .Exchange-swap-box {
      width: 38.75rem;
    }
  }

  @media (max-width: 1300px) {
    .Exchange-list.large {
      display: none;
    }

    .Exchange-list.small {
      display: table;
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 1500px) {
    .Exchange-content {
      grid-template-columns: 1fr auto;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }

  @media (max-width: 1350px) {
    .ExchangeChart.tv .ExchangeChart-bottom {
      top: 139px;
    }

    /* .Exchange-swap-box {
      width: 100%;
    } */

    .ExchangeChart.tv .ExchangeChart-bottom-header {
      display: block;
    }

    .ExchangeChart.tv .ExchangeChart-bottom-stats {
      margin-left: 0;
    }

    .ExchangeChart.tv .ExchangeChart-bottom-content {
      position: absolute;
      bottom: 0.8rem;
      left: 0;
      right: 0;
      top: 3.875rem;
    }

    .Exchange-list-tabs {
      margin-top: 0;
    }

    .Exchange-wallet-tokens {
      display: none;
    }

    .Exchange-swap-placeholder {
      display: none;
    }

    .ExchangeChart {
      height: 38.75rem;
    }

    .Exchange-lists.large {
      display: block;
      background: none;
      padding: 0;
    }

    /* .Exchange-lists.small {
      display: block;
    } */

    .Exchange-swap-box .TokenSelector .Modal {
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .order-error-message {
      white-space: pre-line;
      display: block;
      margin-bottom: 10px;
    }

    .Exchange-swap-box .TokenSelector .Modal-content,
    .Exchange-swap-box .Selector .Modal-content {
      /* display: block;
      margin: 0;
      padding: 1.5rem;
      box-sizing: border-box;
      height: auto;
      border: 1px solid #1c0e6a;
      width: 100%; */
    }

    .Exchange-swap-box .TokenSelector .Modal-content,
    .Selector .Modal-content {
      width: 41.85rem;
      position: absolute;
      max-height: 80vh;
      overflow: hidden;
      border: none;
      top: auto;
      bottom: auto;
    }

    .Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
      overflow-y: auto;
      max-height: calc(70vh - 8.525rem);
      scroll-margin-right: 38.75rem;
      overscroll-behavior: smooth;
      scrollbar-width: thin;
      padding-right: 0.5rem;
      margin: 1.5rem 0.8rem 1.5rem 1.5rem;
    }

    .Exchange-swap-box .TokenSelector .Modal,
    .Selector .Modal {
      align-items: center;
      justify-content: center;
    }

    input.Exchange-swap-input {
      max-width: 15.5rem;
    }

    .ExchangeChart-bottom-controls .Tab .Tab-option {
      width: 3.875rem;
      padding: 0.465rem 0;
      font-size: 1.25rem;
    }
  }

  .Exchange-left {
    max-height: calc(100vh - 62px);
    overflow-y: auto;
  }

  .Exchange-left .ExchangeChart-bottom {
    border: none;
    overflow: hidden;
  }

  @media (max-width: 700px) {
    .PositionEditor .Modal-content {
      width: 90vw;
    }

    .Exchange-content {
      grid-template-columns: 1fr;
    }

    .Exchange-swap-box {
      width: 100%;
    }

    .ExchangeChart-top-inner {
      grid-template-columns: auto auto auto;
      grid-column-gap: 0.8rem;
    }

    .ExchangeChart-title {
      font-size: 1.85rem;
    }

    .ExchangeChart-additional-info {
      /* display: none; */
    }

    .Exchange-content {
      padding: 1.5rem;
      padding-top: 1.5rem;
    }

    .Exchange-swap-box .TokenSelector .Modal {
      margin: 0 1.5rem;
    }

    .Exchange {
      padding-top: 1.5rem !important;
    }

    .Exchange-right {
      /* padding-bottom: 90px; */
    }

    .Exchange-lists.small {
      display: block;
    }

    .Exchange-lists.large {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    .ExchangeChart.tv .ExchangeChart-bottom-content {
      bottom: 0;
      top: 0;
    }
  }

  .fz-12 {
    font-size: 12px;
  }

  .Exchange-updated-ui {
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding-top: 0px !important;
    padding-bottom: 0 !important;

    @media screen and (max-width: 700px) {
      padding-bottom: 20px !important;
    }

    .Exchange-content {
      padding-left: 8px;
      padding-right: 8px;
      grid-gap: 0;
      padding-bottom: 0;
      margin-top: 0;

      // height: calc(100vh - 62px) !important;
      .ExchangeChart-top {
        border-radius: 0;

        @media screen and (min-width: 1351px) {
          height: 76.5px;
        }
      }

      .ExchangeChart-top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        background: none;
      }

      .swap-box-v2 {
        border-radius: 0;
        margin-bottom: 0;
        background: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .Exchange-swap-option-tabs {
          // border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 12px;
          // padding: 0.1px;
          background: #242323;

          .Tab-option {
            border-radius: 12px;

            img {
              width: 20px;
              height: 20px;
            }
          }


        }

        @media screen and (max-width: 700px) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .Exchange-swap-order-type-tabs {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        display: flex;
        background: #242323;

        .Tab-option {
          padding: 12px 0px;
          font-size: 12px;
          border-radius: 12px;
          font-style: normal;
          font-weight: 500 !important;
          line-height: 140%;
          margin-right: 0px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 140%;
          color: #807E7C !important;
        }

        .active {
          background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
          color: white !important;
        }
      }

      .ExchangeChart-bottom {
        top: 75.78px;
        border-radius: 0;
      }

      .App-box-highlight {
        border-radius: 0;
      }

      .Exchange-swap-box {
        min-height: calc(100vh - 62px) !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        ::--webkit-scrollbar {
          display: none !important;
        }
      }

      .info-main-price {
        .deltaChange {
          display: none;
        }

        .last-price {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;

          // @media screen and (max-width: 1350px) {
          //   font-size: 24px;
          // }
        }
      }

      @media screen and (max-width: 1350px) {
        padding-bottom: 46px;

        .ExchangeChart-bottom {
          display: none;
        }

        .ExchangeChart {
          height: fit-content !important;
        }

        .Exchange-lists {
          padding-left: 16px;
          padding-right: 16px;
        }

        .Exchange-should-show-position-lines {
          display: none;
        }

        .ExchangeChart-top-inner {
          .ExchangeChart-title {
            margin-bottom: 0;
          }

          .changed-24h {
            display: none;
          }

          .ExchangeChart-additional-info {
            width: 50%;
            margin-top: 16px;

            @media screen and (max-width: 700px) {
              width: 30%;

              .Tooltip-popup {
                width: 300px !important;
                white-space: pre-wrap;
                word-wrap: break-word;
              }

              .custom {
                .Tooltip-popup {
                  width: 250px !important;
                }
              }
            }

            @media screen and (max-width: 1200px) {
              .custom2 {
                .Tooltip-popup {
                  width: 250px !important;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 700px) {
        .ExchangeChart-top-inner {
          gap: 16px !important;
        }

        .Exchange-left {
          .ExchangeChart-top {
            padding-top: 0 !important;
          }
        }

        .Exchange-right {
          padding-left: 0px;
          padding-right: 0px;
          background: none !important;

          .Exchange-swap-box {
            border-radius: 12px !important;
            padding-left: 16px;
            padding-right: 16px;
          }
        }

        .ExchangeChart-top {
          // border-bottom: none !important;
        }

        .exchange-section-first {
          margin-bottom: 16px !important;
        }

        .Exchange-swap-ball {
          top: -28px;
        }
      }
    }

    .Exchange-lists {
      background: none;

      .Exchange-list-tabs {
        .active {
          background: #333030 !important;
        }

        .Tab {
          border-radius: 12px;
          padding: 12px 16px !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }

        .Tab-option {
          font-size: 14px !important;
        }
      }

      .main-wrapper {
        width: 100%;
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .pad-chart-container {
    display: none;
    z-index: 100;

    @media screen and (max-width: 1350px) {
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      background: #090a11;
      /* White/10 */
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);

      // height: 48px;
      .show-ui-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 16px;

        .chart-tick-container {
          display: flex;
          align-items: center;
          gap: 16px;

          .view-chart {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .chart-positions {
            position: relative;
            top: -2px;
            opacity: 1;
          }
        }

        .index-chart {
          img {
            position: relative;
            top: -2px;
          }
        }
      }

      iframe {
        height: 35vh !important;
      }
    }

    @media screen and (max-width: 700px) {
      iframe {
        height: 40vh !important;
      }
    }
  }

  .chart-pad-show-content {
    @media screen and (max-width: 1350px) {
      padding-bottom: calc(30vh + 55px) !important;
    }

    @media screen and (max-width: 700px) {
      padding-bottom: calc(40vh + 65px) !important;
    }
  }

  .close-container {
    display: flex;
    align-items: center;
    // gap: 16px;
    justify-content: flex-end;
  }

  .order-count {
    font-size: 12px;
    line-height: 120%;
    gap: 4px;
    border: 1px solid #c1ff99;
    // border-radius: 4px;
    // padding: 4px;
  }

  .exchange-info-container {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .Exchange-info-row {
      margin-top: 8px;
      width: calc(50% - 4px);
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  // .Exchange-info-row {
  //   >.clickable {
  //     pointer-events: none;

  //     * {
  //       pointer-events: none;
  //     }
  //   }
  // }

  // .Exchange-swap-section-top {
  //   > .clickable {
  //     cursor: default;

  //     * {
  //       cursor: default;
  //     }
  //   }
  // }

  .Exchange-leverage-slider-container {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    .Exchange-leverage-slider {
      flex: 1 1;
      margin-top: 8px;
      padding-left: 0;
      // padding-right: 0;
    }

    .percentahe-value {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--yellow, #c1ff99);
      padding: 8px;
      width: 62px;
      height: 36px;
      transition: all 150ms ease-in-out;

      &:hover {
        opacity: 0.6;
      }

      &:has(input:focus) {
        opacity: 1;
      }

      span {
        color: #807E7C;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        &:first-child {
          font-weight: 700;
        }
      }

      input {
        width: 30px;
        max-height: 31px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        padding: 0 !important;
        color: var(--yellow, #c1ff99);

        &::placeholder {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          opacity: 0.6 !important;
          line-height: 120%;
          color: var(--yellow, #c1ff99);
        }
      }
    }
  }

  .ExchangeChart-top-inner {
    @media screen and (max-width: 767px) {
      padding-left: 8px !important;
    }
  }

  .Exchange-swap-input-container {
    display: flex;
    justify-content: space-between;

    .usd-txt {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: #fff;
    }
  }

  .toogle-card-container {
    margin-bottom: 16px;

    .Exchange-swap-input-container {
      display: flex;
    }

    .percentahe-value {
      border: 1px solid var(--white-100, #fff);

      input {
        // color: white;
      }

      span {
        color: #807E7C;
      }
    }

    .percentage-btn-selected {
      // border: 1px solid var(--yellow, #FFF);
      background: var(--white-10, rgba(255, 255, 255, 0.1));
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: var(--white-100, #fff);
      }

      .ant-switch {
        background: #7c7979;
      }

      .ant-switch-checked {
        background: #c1ff99 !important;
      }
    }
  }

  .Exchange-list-tabs {
    width: fit-content;
    border-radius: 4px;
    border: none;
    background: transparent;
    border-radius: 12px;

    .Tab-option {
      border-radius: 4px;
      padding: 12px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      color: var(--white-60, var(--text-text-secondary, #807E7C));
    }

    .active {
      background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
      color: var(--white-100, #fff) !important;
    }
  }

  .PositionSeller-modal {
    .Modal-content {
      width: 400px;

      .Modal-body {
        padding: 24px !important;
        padding-top: 16px !important;

        .Exchange-swap-section {
          margin-top: 16px;
        }
      }

      .Modal-close-button {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .Confirmation-box-main {
    width: 320px;
    max-width: 100%;
  }

  .allow-slippage-container {
    display: flex;
    gap: 4px;
    padding: 4px 8px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Yellow, #c1ff99);
    color: var(--Yellow, #c1ff99);
    width: 65px;
    height: 25px;

    input {
      border: none;
      width: 32px;
      padding: 0px;
      margin: 0px;
      font-size: 14px;
      color: var(--Yellow, #c1ff99);
      background: none !important;
    }
  }

  .allow-slippaged-line {
    .Exchange-info-row {
      align-items: center;
    }
  }

  .confirmation-modal {
    .Modal-title-bar {
      margin: 0px !important;
      padding: 16px 24px !important;

      @media screen and (max-width: 700px) {
        padding: 16px !important;
      }
    }

    .Modal-content {
      width: calc(100% - 32px);
      max-width: 424px;
      background: #1A1919 !important;

      .divider {
        display: none;
      }

      .Modal-body {
        border-top: none;
        padding: 0px !important;
        max-height: calc(90vh - 8.525rem);

        .content-container {
          // margin-bottom: 24px;
          padding: 16px 24px;
          max-height: 500px;
          overflow-y: auto;

          .Tab {
            border-radius: 4px;
            // border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
            background: var(--Natural-1, #1a1919);

            .Tab-option {
              padding: 12px 16px;
            }
          }

          @media screen and (max-width: 700px) {
            padding: 16px !important;
            max-height: 420px;
          }
        }
      }
    }
  }

  .Modal-content {
    &:has(.history-container) {
      // max-height: 70vh;

      .Modal-body {
        @media screen and (max-width: 767px) {
          max-height: 80vh !important;
        }
      }
    }
  }

  .history-container {
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
  }

  .positionHistory-modal {
    .Modal-content {
      width: calc(100% - 32px);
      max-width: 424px;
    }
  }

  .order-title-line {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;
    padding: 0 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 700px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .position-info-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .position-info {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    .position-id {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: var(--white-60, #807E7C);
    }

    img {
      width: 40px;
      height: 40px;

      &.small {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color: white;
    }

    .side {
      display: flex;
      padding: 4px 8px;
      background: rgba(255, 206, 87, 0.1);
      border-radius: 4px;
      text-transform: uppercase;
      color: #c1ff99;
      height: 22px;
      justify-content: center;
      align-items: center;
      line-height: 100%;
    }
  }

  .order-title-line-details {
    position: relative;

    .postion-history {
      position: absolute;
      right: 24px;
      top: 12px;
      color: #c1ff99;
      cursor: pointer;

      &:hover {
        color: #c1ff99;
      }

      @media screen and (max-width: 700px) {
        right: 16px;
        top: 30px;
      }
    }

    .side-long {
      background: rgba(3, 245, 174, 0.1) !important;
      color: #c1ff99 !important;
    }

    .side-short {
      background: rgba(246, 71, 93, 0.1) !important;
      color: #f6475d !important;
    }

    .side {
      color: white;
      background: var(--white-10, rgba(255, 255, 255, 0.1));
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      padding: 4px 8px;
    }
  }

  .order-details-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 14px;
    line-height: 120%;

    .title {
      font-weight: 500;
    }
  }

  .square-container {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));

    .Exchange-info-row {
      margin: 0;
    }
  }

  .square-fee-container {
    .divider {
      display: flex !important;
      margin: 0 !important;
    }
  }

  .square-container-yellow {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #c1ff99;
    padding: 12px 16px;

    .order-title {
      font-weight: 500;
    }

    .title {
      font-weight: 500;
      color: #c1ff99;
    }

    .fee-square {
      padding: 8px !important;
    }

    .square-container {
      width: 100%;
    }
  }

  .Exchange-swap-option-tabs {
    display: flex !important;

    .Tab-option {
      flex: 1;
    }
  }

  .content-container {
    .Tab {
      display: flex !important;

      .Tab-option {
        flex: 1;
      }
    }
  }

  .position-change-line {
    display: flex;
    width: 100%;
    font-size: 14px;
    gap: 4px;
    align-items: center;

    .position-change-info {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      text-align: end;

      &:first-child {
        flex: none;
        color: #807E7C;
        width: 110px;
        justify-content: flex-start;
        font-weight: 400 !important;
        line-height: 100%;
        position: relative;
        top: 1px;

        @media screen and (max-width: 700px) {
          width: 87px;
        }
      }
    }

    .change-value {
      font-weight: 700;
    }

    @media screen and (max-width: 700px) {
      font-size: 12px;
    }

    &:first-child {
      .position-change-info {
        font-weight: 400 !important;
      }
    }
  }

  .keep-levergae-modal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .trailing-modal {
    .PositionEditor-info-box {
      margin-top: 8px !important;
    }
  }

  .position-list-risk {
    height: 16px;
    border-radius: 8px;
    background: rgba(59, 222, 183, 0.1);
    padding: 0px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #c1ff99;
    width: fit-content;
    line-height: 10px;
    display: flex;
    align-items: center;
  }

  .position-list-leve {
    height: 16px;
    border-radius: 4px;
    background: rgba(255, 199, 0, 0.2);
    padding: 0px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #ffc700;
    width: fit-content;
    line-height: 10px;
    display: flex;
    align-items: center;
  }

  .chart-token-menu-items {
    .menu-item {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: #302f2f;
    }

    .menu-item-active {
      background-color: #414040 !important;
    }

    .menu-item:hover {
      background-color: #414040 !important;
    }
  }

  .PositionDropdown-extra-options {
    .menu-items {
      top: unset !important;
      min-width: 200px;
      bottom: 20px !important;
      height: fit-content;
    }
  }

  .right-balance {
    display: flex;
    align-items: center;
    gap: 8px;

    >span {
      color: var(--white-60, #807E7C);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      cursor: default;
      /* 19.6px */
    }
  }

  .max-btn {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Yellow, #c1ff99);
    border: none;

    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
  }

  .contient-12-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .showPercentage {
    margin: 12px 0 8px;
    justify-content: flex-end;
  }

  .Exchange-list-title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 700;
  }

  .Exchange-list {
    margin-top: -24px;

    @media screen and (max-width: 1190px) {
      margin-top: 16px;
    }
  }

  .PositionDropdown-dots-icon {
    @media screen and (max-width: 1024px) {
      background: transparent !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 140% !important;
      color: #ffffff !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      border-radius: 8px !important;
      height: 40px;
      gap: 8px;
      display: flex;
      align-items: center;

      &:hover {
        color: #fff !important;
        box-shadow: none !important;
        background-color: var(--white-10, rgba(255, 255, 255, 0.1)) !important;

        &:enabled {
          color: #fff !important;
          box-shadow: none !important;
          background-color: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
        }
      }
    }
  }

  .App-card-options {
    @media screen and (max-width: 1350px) {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }

  .Exchange-lists {
    .App-card-content {
      gap: 12px;
    }

    @media screen and (max-width: 1023px) {
      margin-top: 16px;
    }
  }

  .Exchange-swap-box-inner {
    @media screen and (max-width: 700px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .show-ui-content {

    .index-chart,
    .view-chart {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 4px;
    }
  }

  .add-to-position-modal {
    .position-change-line {
      .position-change-info {
        width: 110px;
        height: 100%;
        display: flex;
        align-items: center;

        span {
          text-align: left;
        }

        @media screen and (max-width: 600px) {
          width: 90px;
        }
      }

      .change-value {
        width: unset !important;
      }
    }
  }

  .info-mobile-50 {
    @media screen and (max-width: 700px) {
      width: 50% !important;
    }
  }

  .ExchangeChart-title-container {
    @media screen and (max-width: 700px) {
      // width: 100% !important;
      margin-right: 16px;
    }
  }

  .Exchange-list-tabs {
    .Tab-option {
      padding: 12px 16px;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }

  .close-container {
    .Exchange-list-action {
      &:disabled {
        background: none !important;
        color: #666 !important;
      }
    }
  }

  .Confirmation-box-button {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .ExchangeChart-top-inner {
    width: fit-content;
    // padding-right: 24px;

    @media screen and (max-width: 1250px) {
      // padding-right: 8px;
    }
  }

  .ExchangeChart-additional-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .exchange-chart-info-container {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    // flex: 1;
    // overflow-x: auto;
    // overflow-y: hidden;
    padding: 6px 0px;
    height: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1461px) {
      gap: 24px;
    }

    @media screen and (max-width: 1400px) {
      gap: 16px;
    }
  }

  .ExchangeChart-top-inner {
    @media screen and (max-width: 1400px) {
      gap: 16px;
    }
  }

  .ExchangeChart-top-inner {
    @media screen and (max-width: 1350px) {
      gap: 16px !important;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 16px;
      padding-left: 8px;

      .exchange-chart-info-container {
        flex-wrap: wrap;
        justify-content: flex-start;
        flex: 1;
        padding: 0px !important;
        font-size: 12px !important;

        // overflow: hidden !important;
        // overflow: hidden !important;
        .ExchangeChart-additional-info {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          margin-top: 0 !important;
          width: 47% !important;
        }

        .info-main-price {
          .ExchangeChart-additional-info {
            align-items: flex-start !important;
          }
        }
      }

      // .max-lerveage {
      //   display: none;
      // }
    }

    // @media screen and (max-width: 700px) {
    //   gap: 16px !important;
    //   align-items: flex-start;
    //   justify-content: space-between;

    //   .exchange-chart-info-container {
    //     flex-wrap: wrap;
    //     justify-content: flex-end;
    //     gap: 16px;
    //     padding: 0px !important;
    //     font-size: 12px !important;
    //     // overflow: hidden !important;
    //     flex: none !important;
    //     max-width: 50%;

    //     .ExchangeChart-additional-info {
    //       align-items: flex-end;
    //       display: flex;
    //       flex-direction: column;
    //       margin-top: 0 !important;
    //       width: 40% !important;
    //     }

    //     .info-main-price {
    //       .ExchangeChart-additional-info {
    //         align-items: flex-start !important;
    //       }
    //     }
    //   }

    //   .max-lerveage {
    //     display: none;
    //   }
    // }
  }

  .Exchange-right {
    @media screen and (max-width: 700px) {
      margin-top: 16px;
    }
  }

  .ExchangeChart-top {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1350px) {
      overflow: unset !important;
      flex-direction: column;
      gap: 16px;
      padding: 0 16px;
      align-items: flex-start !important;
      background: transparent;

      .trade-version {
        border-right: none;
      }

      .ExchangeChart-top-inner {
        flex-direction: column;
      }
    }
  }

  .ExchangeChart-top-inner {
    @media screen and (max-width: 1350px) {
      overflow: unset !important;
    }

    &::-webkit-scrollbar {
      height: 2px !important;
    }

    .ExchangeChart-info-label {
      white-space: nowrap;
    }
  }

  .Exchange-left {
    // &::-webkit-scrollbar {
    //   width: 8px !important;
    // }

    // &::-webkit-scrollbar-track {
    //   width: 8px !important;
    // }

    // &::-webkit-scrollbar-thumb {
    //   width: 8px !important;
    // }

    @media screen and (max-width: 1350px) {
      padding-bottom: 16px;
      padding-top: 12px;
      overflow-x: hidden;

      // &::-webkit-scrollbar {
      //   width: 4px !important;
      // }

      // &::-webkit-scrollbar-track {
      //   width: 4px !important;
      // }

      // &::-webkit-scrollbar-thumb {
      //   width: 4px !important;
      // }
    }

    @media screen and (max-width: 700px) {
      overflow: unset !important;
      padding-bottom: 16px;
      padding-top: 16px;
    }
  }

  .ExchangeChart-title-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-right: rgba(255, 255, 255, 0.1) 1px solid;
    // padding-right: 8px;

    @media screen and (max-width: 1350px) {
      border-right: none;
    }
  }

  .max-leverage-container {
    // min-width: 148px !important;
    padding-right: 8px;
    position: relative;

    @media screen and (max-width: 1350px) {
      min-width: unset !important;
    }
  }

  .Exchange-swap-section-top {
    .Tooltip-handle {
      color: #807E7C;
    }
  }

  .processing {
    display: flex;
    gap: 8px;
    color: var(--functional-orange, #ffa722);
  }

  .ExchangeChart-additional-info {
    div {
      &:last-child {
        font-weight: 700 !important;
      }
    }
  }

  .exchange-chart-info-container {
    .Tooltip-popup {
      width: 290px !important;
      padding: 16px !important;
      height: max-content !important;
      white-space: wrap !important;

      .customTooltip {
        div {
          font-weight: 400 !important;
        }
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: #5a5a5a;
        position: absolute;
        rotate: 45deg;
        bottom: -4px;
        left: 50%;
      }
    }
  }

  .ExchangeChart-info-label {
    position: relative;
    display: flex;

    .custom-antd-tooltip {
      cursor: help;
      text-decoration: none;
      // text-decoration-style: dotted;
      // position: relative;
      width: 100%;
    }
  }

  .custom-popup-antd-tooltip {
    // left: 767px !important;
    // top: -2px !important;
    // .ant-tooltip-arrow {
    //   left: 60% !important;
    // }
  }

  .ExchangeChart-title-container {
    &:hover {}
  }

  #headlessui-menu-button-5 {
    width: 100%;
    height: 100%;
  }

  .chart-token-selector,
  .ExchangeChart-title {
    width: 100%;
    height: 100%;
    padding-right: 8px;
    display: flex;
    align-items: center;
  }

  .max-leverage-container {
    width: 100%;
    // height: 100%;

    @media screen and (max-width: 1250px) {
      background: var(--Border_Highlight, rgba(255, 255, 255, 0.1)) !important;
    }
  }

  .Exchange-left {
    @media screen and (max-width: 767px) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .Exchange-right {
    border-left: none !important;
    // background: linear-gradient(180deg, #11231e 0%, #191b2e 100%) !important;
    margin-left: 8px;
    border-radius: 16px;
  }

  .App-slider .rc-slider-rail {
    width: 100%;
    background-color: #101233;
    border-radius: 0px !important;
    // background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(40, 45, 74, 1) 100%);
  }

  .App-slider .rc-slider-track {
    background: #c1ff99;
  }

  .App-slider .rc-slider-dot {
    border: none;
    border-radius: 1px;
    width: 2px;
    margin-left: 0px;
    background: #101233;
  }

  .App-slider .rc-slider-dot-active {
    background: #c1ff99;
  }

  .App-slider .rc-slider-handle {
    background-color: #191b2e;
    border: 2px solid #c1ff99;
    border-radius: 50%;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    height: 18px;
    margin-top: -7px;
    position: absolute;
    touch-action: pan-x;
    width: 18px;
  }

  .App-slider .rc-slider-handle:active {
    box-shadow: 0 0 0.4rem 0.6rem #ffcd572c;
  }

  .App-slider .rc-slider-mark-text,
  .App-slider .rc-slider-mark-text-active {
    color: white;
    opacity: 0.5;
  }

  .App-slider .rc-slider-mark-text:hover,
  .App-slider .rc-slider-mark-text-active:hover {
    opacity: 1;
  }

  .rc-slider-tooltip {
    z-index: 5;
  }

  .rc-slider-tooltip-inner {
    box-shadow: none;
    padding: 0.465rem 0.8rem;
    background: #303652;
  }

  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: #303652;
  }

  .normalSlider {
    .rc-slider-rail {
      background: rgba(255, 255, 255, 0.1);
      height: 8px;
    }

    .rc-slider-step {
      .rc-slider-dot {
        top: -3px !important;
        width: 10px !important;
        height: 10px !important;
        border-radius: 100px;
        border: 2px solid #333030;
        background: #000000CC;

        &:first-child {
          left: 1.9px;
        }
      }

      .rc-slider-dot-active {
        background: #c1ff99;
        border: 2px solid #1A1919 !important;
        width: 12px !important;
        height: 12px !important;
        top: -4px !important;
      }
    }

    .rc-slider-mark {
      top: 22px;
    }

    .rc-slider-mark-text-active {
      opacity: 1 !important;
    }

    .rc-slider-handle {
      top: 5px;
    }

    .rc-slider-track {
      height: 8px;
    }

    .rc-slider-mark-text {
      opacity: 0.6;
    }
  }

  .exchange-chart-info-container {
    @media screen and (max-width: 1350px) {
      .info-main-price {
        top: 1px !important;

        .ExchangeChart-additional-info {
          align-items: flex-start !important;
          width: 100%;
        }

        width: fit-content !important;
        justify-content: flex-start !important;
        text-align: left !important;

        .last-price {
          width: fit-content !important;
        }
      }
    }
    @media screen and (max-width: 900px) {
      .info-main-price {
        top: 51px !important;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .trade-version {
      top: -1px !important;
    }
  }

  @media screen and (max-width: 767px) {
    .ExchangeChart-top-inner {
      padding-left: 0 !important;
    }

    .trade-version {
      right: 16px !important;
    }

    .exchange-chart-info-container {
      .info-main-price {
        top: 1px !important;
        left: 170px !important;
      }
    }
  }

  .Exchange-swap-section {
    border-radius: 12px !important;
    margin-bottom: 8px;
  }

  .Modal-content {
    // overflow-y: unset !important;
    max-height: 90vh;
  }

  .Modal-body {
    overflow-y: auto !important;
    margin: 0 !important;
  }

  .risk.low {
    color: #7EE55C;
  }

  .risk.medium {
    color: #FED74C;
  }

  .risk.high {
    color: #f34545;
  }

  .PositionsList {
    .Exchange-list-tab-container {
      .Tab {
        background: none !important;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .ExchangeChart-info-label {
      text-align: right;

      span {
        text-align: right;
      }
    }

    .ExchangeChart-info-label {
      width: unset !important;
    }

    .ExchangeChart-additional-info {
      width: 30% !important;
    }

    .ExchangeChart-title-container {
      width: unset !important;
    }
  }

  @media screen and (max-width: 767px) {}

  .Exchange-lists {
    table {
      border-collapse: separate;
      border-spacing: 0 12px;
      margin-top: -13px;

      .Exchange-list-header {
        position: relative;
        top: unset;
      }

      .table-content {
        background: #242323;
        position: relative;

        .flag {
          position: absolute;
          border-radius: 0px 24px 24px 0px;
          width: 4px;
          height: 37px;
          left: -0.5px;
          top: calc(50% - 37px / 2);
        }

        .Exchange-list-info-label {
          position: relative;
        }

        td {
          &:first-child {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    .Exchange-list-header {
      th {
        color: #807E7C !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 140% !important;
      }
    }
  }

  .no-array-length {
    margin-top: -11px !important;
  }

  .select-market-container {
    background: #242323 !important;
    border: none !important;
    box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
    width: 424px !important;
  }

  .ExchangeChart-top-inner {
    .chart-token-menu-items {
      @media screen and (max-width: 900px) {
        width: calc(50vw - 32px) !important;
        top: 5rem !important;
      }

      @media screen and (max-width: 767px) {
        width: calc(100vw - 32px) !important;
      }
    }
  }

  .Tooltip-popup {
    .Tooltip-row {
      .label {
        color: #807E7C !important;
      }
    }
  }

  .Exchange-list-tab-container {
    @media screen and (max-width: 969px) {
      .Tab {
        width: unset !important;
        overflow-x: scroll;
        max-width: calc(50vw - 32px);
        padding-bottom: 10px;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          height: 2px !important;
        }

        &::-webkit-scrollbar-track {
          height: 2px !important;
        }

        &::-webkit-scrollbar-thumb {
          height: 2px !important;
        }

        .Tab-option {
          white-space: nowrap;
        }
      }
    }

    @media screen and (max-width: 700px) {
      .Tab {
        max-width: calc(100vw - 32px);
      }
    }
  }

  .last-price {
    @media screen and (max-width: 1110px) {
      text-align: left;
    }
  }

  .Exchange-swap-option-tabs {
    .Tab-option {
      .Tab-option-icon {
        width: 20px;
        height: 20px;
        transform: scale(1) !important;
      }
    }
  }

  .box-trade-v2 {
    .divider {
      margin: 16px 0px;
    }
  }

  .Exchange-swap-box {
    .swap-box-v2 {
      @media screen and (max-width: 767px) {
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }
  }

  .Exchange-swap-button-container {
    button {
      border-radius: 8px;
    }
  }

  .flag {
    position: absolute;
    border-radius: 0px 24px 24px 0px;
    width: 4px;
    height: 37px;
    left: 0;
    top: calc(50% - 37px / 2);

    &.isLong {
      background-color: #c1ff99;
    }

    &.isShort {
      background-color: #f6475d;
    }
  }

  .Exchange-right {
    @media screen and (max-width: 700px) {
      border: none !important;
      width: calc(100vw - 32px);
      margin-left: auto;
      margin-right: auto;

      .Exchange-swap-box {
        border-radius: 12px !important;
      }
    }
  }

  .Exchange-left {
    @media screen and (max-width: 700px) {
      .ExchangeChart-top {
        border-bottom: 1px solid var(--white-10, rgba(255, 255, 255, 0.1)) !important;
        background: none;
      }
    }

    // margin-bottom: 16px;
  }

  .muted {
    font-weight: 400;
    line-height: 16.8px;
  }

  .Exchange-left {
    @media screen and (max-width: 1350px) {
      background: transparent;
    }
  }

  .exchange-chart-info-container {
    @media screen and (max-width: 700px) {
      gap: 8px;
    }
  }

  .Exchange-swap-section-top {
    .Tooltip-popup {
      width: 300px !important;

      span {
        white-space: wrap;
      }
    }
  }

  .App-card-options {
    @media screen and (max-width: 1350px) {
      button {
        height: 40px;
        padding: 8px 16px;
        margin: 0;
      }
    }
  }

  .pad-chart-container {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(17px);
  }

  .PositionDropdown-extra-options {
    .menu-items {
      border-radius: 12px !important;
      box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
      background: #242323 !important;

      .menu-item {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        padding: 8px 16px;
        color: #fff;

        @media screen and (min-width: 1300px) {
          border-radius: 0;
        }

        &:hover {
          background: rgba(239, 236, 235, 0.08) !important;
        }
      }
    }
  }

  .Exchange-list-info-label {
    .leverage-box {
      position: relative;
      top: -1px;
    }
  }

  .Modal-body {
    max-height: unset !important;
  }

  .PositionsList {
    .Exchange-list-info-label {
      .Tooltip-popup {
        width: 320px !important;
      }
    }
  }

  .Modal-content {
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .swap-box-v2 {
    .Tab-option {
      font-size: 14px !important;
    }
  }

  .Exchange-content {
    min-height: calc(100vh - 62px);
    padding: 0px 8px 8px 8px;
  }
}

.no-data-card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  width: auto;
  height: 150px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: #807E7C;
}

.trade-version {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
  padding-left: 16px;
  // padding-right: 16px;
  margin-left: 24px;
}

.trade-version-container {
  display: flex;
  border-radius: var(--base-unit-xs-8, 8px);

  // border: 1px solid var(--white-10, rgba(255, 255, 255, 0.10));
  background: rgba(255, 255, 255, 0.1) !important;

  a {
    text-decoration: none;
  }

  .version-tab {
    border-radius: var(--base-unit-xs-8, 8px);
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .star {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.ExchangeChart-title {
  position: relative;
}

.version-tab-active {
  // background: var(--main-primary, #C1FF99);
  color: var(--black-100, #c1ff99);
  border: 1px solid #c1ff99;
}

.custom-antd-tooltip {
  cursor: pointer;
  text-decoration: none;
  text-decoration-style: dotted;
  // position: relative;
  width: 100%;
  cursor: help;
}

.no-position,
.no-array-length {
  td {
    padding: 0px !important;
  }
}

.no-data-card {
  margin: 0 !important;
}

.chart-pad-open {
  background: black !important;

  .chart-tick-container {
    .Checkbox {
      top: 2px !important;
    }
  }
}

.chart-token-menu {
  .menu-items {
    padding: 0 !important;
  }

  .chart-token-menu-items {
    background: #20233b !important;
    border: none !important;
    width: fit-content !important;
    right: unset !important;
    left: 0 !important;
  }
}

.ExchangeChart-title {
  .chart-token-menu-items {
    top: 65px;

    @media screen and (max-width: 1250px) {
      top: 6.5rem !important;
    }
  }
}

.max-leverage-container {
  background: var(--Border_Highlight, rgba(255, 255, 255, 0.1)) !important;
}

.swap-box-v2 {
  height: 76px !important;
}

.no-data-record {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  width: auto;
  height: 150px;
  border-radius: 12px;
  // background-color: #212333;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: #807E7C;
  border: 1px solid transparent;
}

.Modal-body {
  .history-container {
    .align-right {
      font-weight: 700;
    }
  }
}

.leverage-box {
  display: unset !important;
  line-height: 100% !important;
  padding: 1px 4px !important;
}

.Exchange {
  .Exchange-swap-section-top {
    div {
      font-weight: 500 !important;
    }

    span {
      font-weight: 500 !important;
    }
  }
}

.Exchange-list-tabs {
  .Tab-option {
    border-radius: var(--Base-Unit-S-12, 12px) !important;
  }
}

.swap-v2-wrapper {
  .Exchange-swap-box-info {
    gap: 0;
  }
}

.swap-v1-container {
  .ExchangeChart {
    border-bottom: none;
  }
}

.PositionDropdown-dots-icon {
  margin-top: 0 !important;

  img {
    top: 0 !important;
  }
}

.set-colateral {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 4px;
  align-items: center;
  border-radius: var(--Base-Unit-S-12, 12px);
  // border: 1px solid var(--Border, rgba(255, 255, 255, 0.10));
  background: #11121b;

  .title {
    color: var(--Text-Text_Secondary, #807E7C);
    font-size: 14px;
  }
}

.route-underlying-info {
  display: flex;
  padding: var(--Base-Unit-XS-8, 8px);
  border-radius: var(--Base-Unit-S-12, 12px);
  background: var(--Border, rgba(255, 255, 255, 0.1));
  align-items: center;
  gap: var(--Border_radius, 4px);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 100%;
}

.theme--light {

  .route-underlying-info,
  .set-colateral {
    background: rgba(38, 77, 65, 0.07) !important;

    .TokenSelector-box {
      svg {
        filter: brightness(0);
      }

      &:hover {
        svg {
          filter: brightness(1) !important;
        }
      }
    }
  }
}

.route-tokens {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.exchange-take-profit-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;

  .Exchange-swap-section-top {
    padding: 0;

    .muted {
      line-height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .Exchange-swap-section-bottom {
    padding: 0;
    padding-left: 4px;

    .Exchange-swap-input-container {
      margin-top: 0;
      width: 100%;

      input {
        margin-right: 4px;
        padding-right: 0;
        font-weight: 700;
        font-size: 16px;
        text-align: right;

        &::placeholder {
          color: #807E7C;
        }
      }
    }
  }
}

.profit-loss-label {
  gap: 4px !important;
  margin-top: 0 !important;

  span {
    font-weight: 700 !important;
  }
}

.tab-active-long {
  .active {
    background: #C1FF99 !important;
  }
}

.tab-active-short {
  .active {
    background: #F99 !important;
  }
}


.App-slider .rc-slider-rail {
  width: 100%;
  background-color: #101233;
  border-radius: 0px !important;
  // background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(40, 45, 74, 1) 100%);
}

.App-slider .rc-slider-track {
  background: #c1ff99;
}

.App-slider .rc-slider-dot {
  border: none;
  border-radius: 1px;
  width: 2px;
  margin-left: 0px;
  background: #101233;
}

.App-slider .rc-slider-dot-active {
  background: #c1ff99;
}

.App-slider .rc-slider-handle {
  background-color: #191b2e;
  border: 2px solid #c1ff99;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  height: 18px;
  margin-top: -7px;
  position: absolute;
  touch-action: pan-x;
  width: 18px;
}

.App-slider .rc-slider-handle:active {
  box-shadow: 0 0 0.4rem 0.6rem #ffcd572c;
}

.App-slider .rc-slider-mark-text,
.App-slider .rc-slider-mark-text-active {
  color: white;
  opacity: 0.5;
}

.App-slider .rc-slider-mark-text:hover,
.App-slider .rc-slider-mark-text-active:hover {
  opacity: 1;
}

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: #303652;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303652;
}

.normalSlider {
  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.1);
    height: 8px;
  }

  .rc-slider-step {
    .rc-slider-dot {
      top: -3px !important;
      width: 10px !important;
      height: 10px !important;
      border-radius: 100px;
      border: 2px solid #333030;
      background: #000000CC;

      &:first-child {
        left: 1.9px;
      }
    }

    .rc-slider-dot-active {
      background: #c1ff99;
      border: 2px solid #1A1919 !important;
      width: 12px !important;
      height: 12px !important;
      top: -4px !important;
    }
  }

  .rc-slider-mark {
    top: 22px;
  }

  .rc-slider-mark-text-active {
    opacity: 1 !important;
  }

  .rc-slider-handle {
    top: 5px;
  }

  .rc-slider-track {
    height: 8px;
  }

  .rc-slider-mark-text {
    opacity: 0.6;
  }
}
.longInactive {
  color: #807E7C !important;
}

.shortInactive {
  color: #807E7C !important;
}
.Exchange-right {
  border-left: none !important;
  // background: linear-gradient(180deg, #11231e 0%, #191b2e 100%) !important;
  margin-left: 8px;
  border-radius: 16px;
}
.ExchangeChart {
  position: relative;
  height: 49.6rem;
  background: #1A1919;
  border-radius: 16px;
  /* margin-right: 16px; */
  iframe {
    border-radius: 16px;
  }
}

.PositionSeller-modal {
  .PositionSeller-receive-row {
    .Modal-content  {
      height: fit-content !important;
    }
  }
}
